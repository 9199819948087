/* Reset and base styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: white;
    color: #202124;
    line-height: 1.4;
}

/* Container styles */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding: 2rem 1rem;
}

/* Logo styles */
.stella-logo {
    font-size: 5rem;
    font-weight: bold;
    color: #1a0dab;
    margin-bottom: 0.25rem;
    letter-spacing: -1px;
    font-family: arial, sans-serif;
}

.subtitle {
    font-size: 0.875rem;
    color: #4d5156;
    margin-bottom: 2rem;
}

/* Main content wrapper */
.content-wrapper {
    width: 100%;
    max-width: 584px;
    margin: 0 auto;
}

/* Input styles */
.url-input {
    width: 100%;
    height: 44px;
    padding: 0 16px;
    font-size: 14px;
    border: 1px solid #dfe1e5;
    border-radius: 24px;
    margin-bottom: 1rem;
    outline: none;
    transition: all 0.2s ease;
}

.url-input:hover {
    border-color: #e0e0e0;
    box-shadow: 0 1px 6px rgba(32,33,36,.28);
}

.url-input:focus {
    border-color: #4285f4;
    box-shadow: 0 1px 6px rgba(32,33,36,.28);
}

.url-input::placeholder {
    color: #9aa0a6;
}

/* Controls container */
.controls {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-bottom: 2rem;
}

/* Select styles */
.persona-select {
    height: 36px;
    padding: 0 24px 0 12px;
    font-size: 14px;
    border: 1px solid #dadce0;
    border-radius: 4px;
    background-color: #fff;
    color: #202124;
    cursor: pointer;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%3E%3Cpath%20fill%3D%22%23666%22%20d%3D%22M7%2010L0%203h14z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 10px;
}

.persona-select:hover {
    border-color: #8f949a;
}

.persona-select:focus {
    border-color: #4285f4;
    outline: none;
}

/* Button styles */
.test-button {
    height: 36px;
    padding: 0 16px;
    font-size: 14px;
    background-color: #f8f9fa;
    border: 1px solid #dadce0;
    border-radius: 4px;
    color: #3c4043;
    cursor: pointer;
    transition: all 0.2s ease;
}

.test-button:hover:not(:disabled) {
    border-color: #8f949a;
    background-color: #f8f9fa;
    box-shadow: 0 1px 1px rgba(0,0,0,.1);
}

.test-button:disabled {
    background-color: #f8f9fa;
    border-color: #dadce0;
    color: #80868b;
    cursor: not-allowed;
}

/* Status styles */
.status-container {
    margin-bottom: 1rem;
    font-size: 14px;
    color: #4d5156;
}

.progress-bar {
    margin-top: 8px;
    height: 4px;
    background-color: #f1f3f4;
    border-radius: 2px;
    overflow: hidden;
}

.progress-bar-fill {
    height: 100%;
    background-color: #4285f4;
    transition: width 0.3s ease;
}

/* Error message styles */
.error-message {
    padding: 12px;
    margin-bottom: 1rem;
    background-color: #fce8e6;
    color: #d93025;
    border: 1px solid #fad2cf;
    border-radius: 4px;
    font-size: 14px;
}

/* Results stream styles */
.results-stream {
    height: 400px;
    overflow-y: auto;
    border: 1px solid #dfe1e5;
    border-radius: 8px;
    background-color: white;
}

.stream-message {
    padding: 12px 16px;
    font-size: 14px;
    border-bottom: 1px solid #f1f3f4;
    transition: background-color 0.2s ease;
}

.stream-message:last-child {
    border-bottom: none;
}

.stream-message:hover {
    background-color: #f8f9fa;
}

/* Footer styles */
.footer {
    margin-top: auto;
    padding-top: 2rem;
    font-size: 12px;
    color: #70757a;
    text-align: center;
}

/* Loading animation */
@keyframes spin {
    to { transform: rotate(360deg); }
}

.loading-spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid #4285f4;
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
}

/* Responsive styles */
@media (max-width: 640px) {
    .stella-logo {
        font-size: 4rem;
    }

    .content-wrapper {
        padding: 0 1rem;
    }

    .controls {
        flex-direction: column;
    }

    .test-button,
    .persona-select {
        width: 100%;
    }
}

/* Scrollbar styles */
.results-stream::-webkit-scrollbar {
    width: 8px;
}

.results-stream::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.results-stream::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
}

.results-stream::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}

.analysis-container {
    font-family: arial, sans-serif;
    line-height: 1.6;
}

.analysis-container h2 {
    border-bottom: 2px solid #e8eaed;
    padding-bottom: 0.5rem;
}

.analysis-container h3 {
    color: #202124;
    border-bottom: 1px solid #e8eaed;
    padding-bottom: 0.25rem;
}

.analysis-container ul {
    margin-top: 0.5rem;
}

.analysis-container li {
    padding-left: 1rem;
    position: relative;
}

.analysis-container li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: #5f6368;
}

.stream-message.analysis-result {
    padding: 1.5rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}


/* Logo styles */
.logo {
    font-size: 72px;
    font-weight: bold;
}

.logo span:nth-child(1) { color: #0063ff; }
.logo span:nth-child(2) { color: #1470ff; }
.logo span:nth-child(3) { color: #2a81ff; }
.logo span:nth-child(4) { color: #3d84fb; }
.logo span:nth-child(5) { color: #4589f8; }
.logo span:nth-child(6) { color: #6099fa; }

/* Info and footer styles */
.info {
    color: #666;
    font-size: 13px;
    margin-top: 40px;
}

@keyframes pulse {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
}

.animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.progress-tracker {
    width: 100%;
    max-width: 800px;
    margin: 2rem auto;
    padding: 0 1rem;
    position: relative;
}

.steps-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
}

.step-item {
    display: flex;
    align-items: center;
    gap: 8px;
}

.step-circle {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #e5e7eb;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4b5563;
    font-weight: 500;
    transition: all 0.3s ease;
    flex-shrink: 0;
}

.step-label {
    font-size: 0.875rem;
    color: #4b5563;
    white-space: nowrap;
}

.progress-bar-container {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #e5e7eb;
    transform: translateY(-50%);
    z-index: 1;
}

.progress-bar-fill {
    height: 100%;
    background-color: #3b82f6;
    transition: width 0.5s ease;
}

.step-circle.completed {
    background-color: #10b981;
    color: white;
}

.step-circle.current {
    background-color: #3b82f6;
    color: white;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}